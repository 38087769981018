<template>
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document" style="margin: 5rem 2.5rem;">
        <div class="modal-content" style="background-color:black">
          <div class="modal-header" style="padding: 0.25rem;">
            <!-- <h5 class="modal-title">Modal title</h5> -->
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true"  style="color:white">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align:center;">
            <img src="/favicon.png" style="width:15%">
            <h2 style="color:white;margin-bottom:1rem;margin-top:1rem" class="roboto">JOURLY HERUNTERLADEN</h2>
            <h3 style="color:white;margin-bottom:1rem" class="roboto">Du wünscht dir mehr Inspiration & Austausch mit deinen Jourly Buddys?</h3>
            <button class="btn btn-primary roboto" onclick="window.location.href='https://jour.ly/get';" style="width:200px;height:40px;border-radius: 0;background-color: #00fff2;border-color:#000;background-image: linear-gradient(to right, #f7aaff , #00fff2);">
              HOL DIR JETZT DIE APP
            </button>
          </div>
          <!-- <div class="modal-footer"> -->
            <!-- <button type="button" class="btn btn-primary" @click="saveChanges">Save changes</button> -->
            <!-- <button type="button" class="btn btn-secondary" @click="closeModal">Close</button> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      closeModal() {
        this.$emit('close-modal-event');
      },
      saveChanges() {
        this.closeModal();
      }
    }
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

  /* Override default value of 'none' */
  .modal {
    display: block;
  }
  .roboto {
    font-family: 'Roboto', sans-serif;
  }
</style>