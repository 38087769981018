<template>
  <div class="full-screen" v-if="!isMobile">    
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-12">
              <img id="profile-img" src="/img/brand/white.png" class="profile-img-card" style="max-width: 170px;min-width:100px" @click="goToSite">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 all">
        <h1 class="roboto" style="vertical-align: middle;color:#00fff2;margin-bottom:2rem" align="center">
          Jourly is currently only available on mobile devices. <br>
          Please re-visit this link from a smartphone.
        </h1>
        <h3 class="roboto" style="vertical-align: middle;color:white;margin-bottom:1rem" align="center">what is jourly?</h3>
        <a @click="goToSite" class="btn btn-primary roboto" style="width:200px;height:40px;border-radius: 0;background-color: #00fff2;background-image: linear-gradient(to right, #f7aaff , #00fff2);">VISIT OUR WEBSITE</a>
    </div>
  </div>
  <div class="full-screen" v-else>    
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-2">
            <div class="col-12" style="padding-left:40px">
              <img id="profile-img" src="/img/brand/white.png" class="profile-img-card" style="max-width: 100px;" @click="goToSite">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 all-mobile" id="allMobile" style="display:none">
      <div class="row" @click="showModal">
        <div class="col-12" style="padding-left:0px;padding-right:0px;">
          <img id="banner-img" v-if="jourly.imagePath!=''" :src="jourly.imagePath" @load="imgLoad('Cover')" class="banner-img-card" style="width: 100%;height:200px;opacity:.8;position:relative">
          <div style="position:absolute;width: 100%;margin-top: -33%;">
            <h2 class="roboto" style="vertical-align: middle;color:white;" align="center">
              {{jourly.name}}
            </h2>
            <input disabled type="text" :value="jourly.date" class="form-control form-rounded date-input" style="border: unset;color:white" placeholder="">
          </div>
        </div>
        <div class="col-6" style="text-align:left;padding-left:12px;padding-top:6px;padding-bottom:6px;">
          <p style="color:white;margin-top:3px">
          <img v-if="jourly.userImage!=''" id="rounded-img" :src="jourly.userImage" class="round-img-card" @load="imgLoad('User')" style="width: 35px;border-radius:50%;border: 2px solid #96bdd7;">
            
            <span class="roboto" style="margin-left:5px" v-if="jourly.username!=''">@{{jourly.username}}</span></p>
        </div>
        <div class="col-6" style="text-align:right;padding-right:12px;padding-top:6px;padding-bottom:6px;">
          <p style="margin-top:3px">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ff0031"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>
            <span class="roboto" style="color:#ff0031;font-weight: 400;font-size: .90rem;padding-right: 3px;">{{ jourly.likeCount }}</span>
            <img src="/comment.png"  style="width: 21px;">
            <span class="roboto" style="color:white;font-weight: 400;font-size: .90rem;padding-left: 5px;">{{ jourly.commentCount }}</span>
          </p>
          
          <!-- <button type="button" class="btn btn-dark btn-sm roboto" style="border: 1px solid white;margin-top:5px;border-radius: 0;background-color: transparent;">add as friend</button> -->
        </div>
        <div class="col-12">
          <h2 align="center" style="color:white;font-weight: 350;">
            Deine <span class="love-note" style="font-size: 2.55rem;color: #9ec2f4;">Stories</span>
          </h2>
          <h3 style="color:white;" class="roboto-light">stories that are shared to the world</h3>
        </div>
      </div>
      <hr style="margin-top:1rem;margin-bottom:12px;border: 1px solid #00fff2;margin-left: -15px;margin-right: -15px;">
      <div class="row" @click="showModal" v-if="jourly.privacy=='Public'" style="margin-bottom:150px">
        <div class="col-12">
          <h2 class="roboto" style="color:white;margin-bottom: 0px;" align="center">{{jourly.stop1Name}}</h2>
          <p class="roboto" style="color:#00e7f7;line-height: 1;">{{ jourly.stop1StartDate }} - {{ jourly.stop1EndDate }}</p>
        </div>
        <div class="col-6" style="margin:0px;padding:0px 1px 2px 0px;background-color: #000;">
          <img v-if="images[0]" :src="images[0]" class="round-img-card" style="width:100%;height:100%" @load="imgLoad">
        </div>
        <div class="col-6" style="margin:0px;padding:0px 0px 2px 1px;background-color: #000;">
          <img v-if="images[1]" :src="images[1]" class="round-img-card" style="width:100%;height:100%" @load="imgLoad">
        </div>
        <div class="col-6" style="margin:0px;padding:0px 1px 0px 0px;background-color:#ffffffb8">
          <img v-if="images[2]" :src="images[2]" class="round-img-card blur-to-black" style="padding-right:1px;padding-bottom:100px" @load="imgLoad">
        </div>
        <div class="col-6" style="margin:0px;padding:0px 0px 0px 1px;background-color:#ffffffb8">
          <img v-if="images[3]" :src="images[3]" class="round-img-card blur-to-black" style="padding-left:1px;padding-bottom:100px" @load="imgLoad">
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12">
          <h1 style="color:#a891d6;font-size: calc(2rem + 0.45vw);" align="center"><i class="fas fa-lock"></i></h1>
          <h2 style="color:white;padding: 0px 30px 100px 30px;" align="center">This jourly is only visible to {{jourly.username}}'s friends</h2>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 all-mobile" id="loadingMobile">
      <div class="row align-items-center py-2">
        <div class="col-12">
          <img src="/img/loading.gif" class="profile-img-card" style="max-width: 200px;margin-top:30%">
        </div>
      </div>
    </div>
    <div class="row" @click="showModal" style="position: fixed;bottom: 0px;left: 0px;right: 0px;">
      <div class="col-12">
          <img src="/img/theme/app_footer.png" class="profile-img-card" style="max-width: 100%;">
      </div>
    </div>
    <share-jourly-modal v-if="displayModal" @close-modal-event="hideModal" />
  </div>
</template>
<script>
import ShareJourlyModal from './ShareJourlyModal.vue'
import RequestService from '../../services/request.service';

export default {
  
  components: {
    ShareJourlyModal
  },
  data() {
    return {
     isMobile : false,
     displayModal: false,
     jourly:{
	      name: '',
	      username : '',
	      imagePath : '',
	      userImage : '',
	      // isFeatured : false,
	      // storyCount : 0,
	      // userId: '',
        stop1Name: '',
        stop1StartDate: '',
        stop1EndDate: '',
        commentCount:0,
        likeCount:0,
        privacy: '',
        date:''
      },
      images:[],
      loading:true,
      countImageLoad:0,
      coverImgLoaded:false,
      userImgLoaded:false,
    };
  },
  mounted() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }
    this.getData();
  },
  methods:{
     async getData(){
      this.jourlyId = this.$route.params.id;
      let response =  await RequestService.get('jourly/'+this.jourlyId+'/share-details');
      if(response.length<=0){
        alert("Jourly Not Found!");
        return;
      }
      // let response =  await RequestService.get('jourly/'+this.jourlyId+'/show');
      this.jourly.name = response.name;
      this.jourly.commentCount = response.commentCount;
      this.jourly.likeCount = response.likeCount;
      this.jourly.date = response.date;
      // this.jourly.date = response.date;
      this.jourly.imagePath = response.imagePath;
      if(this.jourly.imagePath==='' || this.jourly.imagePath===null){
        this.coverImgLoaded = true;
      }
      this.jourly.privacy = response.privacy;
      this.jourly.stop1Name = response.stop1Name;
      this.jourly.stop1StartDate = response.stop1StartDate;
      this.jourly.stop1EndDate= response.stop1EndDate;
      this.images = response.images;
      if(response.user){
        if(response.user.username){
          this.jourly.username = response.user.username;
        }
        if(response.user.imagePath){
          this.jourly.userImage = response.user.imagePath;
          if(this.jourly.userImage==='' || this.jourly.userImage===null){
            this.userImgLoaded = true;
          }
        }else{
          this.userImgLoaded = true;
        }
      }else{
        this.userImgLoaded = true;
      }
    },
    goToSite(){
      window.location.href = 'https://jour.ly/';
    },
    showModal() {
        this.displayModal = true;
      },
    hideModal() {
      this.displayModal = false;
    },
    imgLoad(type = 'img'){
      if(type==='Cover'){
        this.coverImgLoaded = true;
      }
      if(type==='User'){
        this.userImgLoaded = true;
      }
      if(this.images.length > this.countImageLoad){
        this.countImageLoad++;
        // console.log(this.countImageLoad);
      }
      if(this.images.length <= this.countImageLoad){
        if(this.coverImgLoaded){
          if(this.userImgLoaded){
            document.getElementById("loadingMobile").style.display='none';
            document.getElementById("allMobile").style.display='unset';
          }
        }
      }
    }
  }
};
</script>
<style scoped>
  @import url('https://allfont.net/allfont.css?fonts=roboto-light');
  @import url('https://fonts.googleapis.com/css2?family=Allura&family=Roboto:wght@300&display=swap');
  @font-face {
  font-family: "LoveNotesScript";
  src: local("LoveNotesScript"),
   url(/LoveNotesScript.ttf) format("truetype");
}
  
  .allura {
    font-family:  'Allura', cursive;
    vertical-align: middle;
    font-size:30px;
    background: -webkit-linear-gradient(left, #f7aaff , #00fff2);
    background: -o-linear-gradient(right, #f7aaff, #00fff2);
    background: -moz-linear-gradient(right, #f7aaff, #00fff2);
    background: linear-gradient(to right, #f7aaff , #00fff2); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .roboto {
    font-family: 'Roboto', sans-serif;
  }
  .love-note {
    font-family: 'LoveNotesScript', sans-serif;
  }
  .roboto-light{
    font-family: 'Roboto Light', arial;
  }
  .blur-to-black{
    position:absolute;
    /* top:0; */
    left:0;
    /* width:250px; */
    /* height:250px;  */
    width:100%;
    /* height:100%; */
    -webkit-mask-image:-webkit-gradient(linear, top, bottom, from(rgba(0,0,0,.7)), to(rgba(0,0,0,0))); 
     mask-image: linear-gradient(to bottom, rgba(0,0,0,.7), rgba(0,0,0,0));
    /* -webkit-mask-image: linear-gradient(to left top, rgba(0,0,0,.2), rgba(0,0,0,.4)), linear-gradient(to left bottom, rgba(0,0,0,.2), rgba(0,0,0,.0));
    -webkit-mask-size: 100% 50%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position:  top,  bottom; */
     /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1)); */
  }
  .full-screen {
    position: fixed;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    z-index: 0;
  }
  .all {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
    padding: 12%;
  }
  .all-mobile {
    overflow-y:auto;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
  }
  .modal {
  /* background: green; */
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
  .date-input{
    border-radius: 2rem;
    width: 50%;
    margin: 0px 25%;
    background-color: #00fff2;
    font-size: 1rem;
    color: white;
    padding: 0rem 0rem;
    text-align: center;
    height: calc(1em + 1rem);
  }
  .form-control:disabled{
    background-color: rgb(0, 255, 242);
    opacity: .9;
}
</style>
