import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL+'api/v1/';

class RequestService {
  async get(url) {
    let data = null;
    await axios
      .get(API_URL + url,{ headers: authHeader() })
      .then(response => {
        data = response.data;
      }).catch(error => {
        if (error.response.status===403||error.response.status===401) {
          localStorage.removeItem('user');
          window.location.reload(true);
        }
      });
      return data;
    // return axios.get(API_URL + url,{ headers: authHeader() });
  }
  async delete(url) {
    let data = null;
    return await axios
      .delete(API_URL + url,{ headers: authHeader() })
      .then(response => {
        data = response.data;
      }).catch(error => {
        if (error.response.status===403||error.response.status===401) {
          localStorage.removeItem('user');
          window.location.reload(true);
        }
      });
      return data;
    // return axios.get(API_URL + url,{ headers: authHeader() });
  }
  async post(url,data) {
    return await axios.post(API_URL + url,data,{ headers: authHeader() }).catch(error => {
      if (error.response.status===403||error.response.status===401) {
        localStorage.removeItem('user');
        window.location.reload(true);
      }
    });
  }
  async put(url,data) {
    return await axios.put(API_URL + url,data,{ headers: authHeader() }).catch(error => {
      if (error.response.status===403||error.response.status===401) {
        localStorage.removeItem('user');
        window.location.reload(true);
      }
    });
  }
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new RequestService();
