import { createRouter, createWebHashHistory,createWebHistory } from "vue-router";

//Jourly Routes
import ShareJourly from "../views/Jourly/ShareJourly.vue";
import ShareList from "../views/List/ShareList.vue";

const routes = [
  // Auth t routes
  {
    path: "/jourly/:id",
    name: "Share Jourly",
    component: ShareJourly,
  },
  {
    path: "/list/:id",
    name: "Share List",
    component: ShareList,
  }
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});
// router.beforeEach((to, from, next) => {
//   let id = to.params.id;
//   const publicPages = ['/share/jourly/'+id,'/share/list/'+id];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');
//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;
